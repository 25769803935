<template>
  <div class="investor-phone" :class="{skeleton: skeleton}">
    <div class="input-group">
      <Phone :id="id" :value="state.mobileNum" :placeholder="placeholder" :disabled="true"/>
      <div class="input-group-append">
        <button class="btn btn-default font-xs" @click="send()" :disabled="skeleton" v-if="!state.authorized">{{ state.sent ? "SMS 재발송" : "SMS 인증" }}</button>
        <button class="btn btn-default gray font-xs" :disabled="skeleton" @click="reset()" v-else>다시 인증</button>
      </div>
    </div>
    <div class="input-group mt-2" v-if="state.sent && !state.expired">
      <Number :id="`${component.name}Code`" :placeholder="`인증 코드 6자리 (00:${state.expSeconds})`" :value.sync="state.code" :allowZero="true" :noComma="true" :maxlength="6" autocomplete="off"/>
      <div class="input-group-append">
        <button class="btn btn-default font-xs" @click="authorize()">인증 완료</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, nextTick, onUnmounted, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import http from "@/scripts/http";
import Number from "@/components/Number";

function Component(initialize) {
  this.name = "componentPhone";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number, Phone},
  mixins: [mixin],
  props: {
    id: String,
    investorSeq: String,
    mobileNum: String,
    placeholder: String,
    skeleton: Boolean,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
      init();
    });

    const state = reactive({
      mobileNum: "",
      sent: false,
      authorized: false,
      expired: false,
      code: "",
      token: "",
      expSeconds: "00"
    });

    const modalParams = store.getters.modalParams(component);
    let timer;

    const reset = () => {
      state.sent = false;
      state.authorized = false;
      state.expired = false;
      state.code = "";
    };

    const send = () => {
      if (!state.mobileNum?.trim()) {
        return store.commit("setSwingMessage", "휴대전화 번호가 비어있거나 유효하지 않습니다. 회사(02-388-2556)로 문의해주세요.");
      }

      http.post(`/api/investors/${props.investorSeq}/codes`).then(() => {
        state.sent = true;
        state.authorized = false;
        state.expired = false;
        state.expSeconds = "59";

        clearInterval(timer);
        store.commit("setSwingMessage", "입력하신 휴대전화 번호로 문자를 발송하였습니다. 확인 후 코드 6자리를 입력해주세요.");

        timer = setInterval(() => {
          const seconds = window.Number(state.expSeconds) - 1;
          state.expSeconds = lib.getNumberWithPadding(seconds);

          if (!seconds) {
            clearInterval(timer);
            reset();
            store.commit("setSwingMessage", "인증 유효 시간이 만료되었습니다.");
          }
        }, 1000);

        nextTick(() => {
          document.getElementById(`${component.name}Code`).focus();
        });
      });
    };

    const init = () => {
      reset();
      state.authorized = false;
      state.mobileNum = props.mobileNum;
      state.token = "";
    };

    const authorize = () => {
      if (!state.code?.trim()) {
        document.getElementById(`${component.name}Code`)?.focus();
        return store.commit("setSwingMessage", "인증 코드를 입력해주세요.");
      }

      const args = {
        code: state.code,
        mobileNum: state.mobileNum
      };

      http.put(`/api/investors/${props.investorSeq}/codes`, args).then(({data}) => {
        state.token = data.body;
        clearInterval(timer);
        store.commit("setSwingMessage", "SMS 인증을 완료하였습니다.");
      }).catch(() => {
        state.code = "";
        nextTick(() => {
          document.getElementById(`${component.name}Code`).focus();
        });
      });
    };

    watch(() => state.token, (next) => {
      emit("update:token", next);
    });

    onUnmounted(() => {
      clearInterval(timer);
    });

    return {component, state, modalParams, reset, send, authorize};
  }
});
</script>

<style lang="scss" scoped>
.investor-phone {
  position: relative;

  .input-group {
    input {
      height: $formHeight;
    }

    .btn {
      border: $px1 solid #ced4da;
      padding-left: 0;
      padding-right: 0;
      width: $px76;

      &.gray {
        background: #e9ecef;
      }
    }
  }
}
</style>